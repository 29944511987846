/* css */
.wrap {
  position: relative;  
  width: 100%;
  height: 500px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}