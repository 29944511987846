.form-item-status {
    margin-bottom: 0;
  }

.bkright{
    border-right:1px solid #cccccc8c;
}

.loading{
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.Hiddle {
  display: none; 
}
.bottomborder{
  border-bottom:1px solid #cccccc8c;
}
